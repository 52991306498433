import React from "react"
import { Link } from "gatsby";

class TestimonialFlat extends React.Component {
    render() {
        return (
            <div className="c-testimonial">
                <div className="container">
                    <div className="row">
                        <div className="c-testimonial__container">
                            <div className="row">
                                <div className="c-testimonial__quote-container">
                                    <blockquote className="c-testimonial__quote">
                                        <p className="c-testimonial__quote-text">“We need to shed light on things we haven’t seen, people we haven’t met, ideas that will change the game for us”</p>
                                    </blockquote>
                                </div>
                                <div className="c-testimonial__link-container">
                                    <Link to="/flat" className="c-testimonial__link c-btn c-btn--text">Link here</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TestimonialFlat