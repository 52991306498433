import React from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

class ServicesCarouselFlat extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            slideIndex: 0,
            updateCount: 0
        }
        this.linkClick = this.linkClick.bind(this)
    }

    linkClick = event => {
        this.slider.slickGoTo(event.target.dataset.slide)
    }

    render() {
        const settings = {
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            infinite: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 1000,
            afterChange: () =>
                this.setState(state => ({updateCount: state.updateCount + 1})),
            beforeChange: (current, next) => this.setState({slideIndex: next})
        };
        return (
            <div className="c-services-carousel">
                <div className="c-services-carousel__header-container">
                    <div className="container">
                        <div className="row">
                            <div className="c-services-carousel__header">
                                <div className="c-services-carousel__subtitle">Our Services</div>
                                <h2 className="c-services-carousel__title">For enterprise R&D, tech strategy &amp; innovation teams</h2>
                                <p className="c-services-carousel__summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut orci quis sem pretium mollis eget eget nulla.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="c-services-carousel__navigation">
                            <button
                                onClick={this.linkClick}
                                data-slide="0"
                                className={this.state.slideIndex === 0 ? 'c-services-carousel__navigation-link is-active' : 'c-services-carousel__navigation-link'}>
                                Deep Tech Scouting
                            </button>
                            <button
                                onClick={this.linkClick}
                                data-slide="1"
                                className={this.state.slideIndex === 1 ? 'c-services-carousel__navigation-link is-active' : 'c-services-carousel__navigation-link'}>
                                Technology Radar
                            </button>
                            <button
                                onClick={this.linkClick}
                                data-slide="2"
                                className={this.state.slideIndex === 2 ? 'c-services-carousel__navigation-link is-active' : 'c-services-carousel__navigation-link'}>
                                Trend Foresight
                            </button>
                            <button
                                onClick={this.linkClick}
                                data-slide="3"
                                className={this.state.slideIndex === 3 ? 'c-services-carousel__navigation-link is-active' : 'c-services-carousel__navigation-link'}>
                                Tech Experts
                            </button>
                            <button
                                onClick={this.linkClick}
                                data-slide="4"
                                className={this.state.slideIndex === 4 ? 'c-services-carousel__navigation-link is-active' : 'c-services-carousel__navigation-link'}>
                                Deep Dives
                            </button>
                            <button
                                onClick={this.linkClick}
                                data-slide="5"
                                className={this.state.slideIndex === 5 ? 'c-services-carousel__navigation-link is-active' : 'c-services-carousel__navigation-link'}>
                                Futorials
                            </button>
                        </div>
                        <div className="c-services-carousel__items">
                            <Slider ref={slider => (this.slider = slider)} {...settings}>
                                <div>
                                    <div className="c-services-carousel__item">
                                        <Link to="/flat/service" className="c-services-carousel__item-content-container">
                                            <div className="c-services-carousel__item-content">
                                                <h3 className="c-services-carousel__item-title">Deep Tech Scouting</h3>
                                                <p className="c-services-carousel__item-summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                            <div className="c-services-carousel__item-link">Tell me more</div>
                                        </Link>
                                        <img src="/images/test/services-carousel-1.png" alt="" className="c-services-carousel__item-media" loading="lazy" />
                                        <div className="c-services-carousel__item-circle">
                                            <img src="/images/test/services-carousel-bg.png" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="c-services-carousel__item">
                                        <Link to="/flat/service" className="c-services-carousel__item-content-container">
                                            <div className="c-services-carousel__item-content">
                                                <h3 className="c-services-carousel__item-title">Technology Radar</h3>
                                                <p className="c-services-carousel__item-summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras cursus tortor nec tempus aliquam. </p>
                                            </div>
                                            <div className="c-services-carousel__item-link">Tell me more</div>
                                        </Link>
                                        <img src="/images/test/services-carousel-2.png" alt="" className="c-services-carousel__item-media" loading="lazy" />
                                        <div className="c-services-carousel__item-circle">
                                            <img src="/images/test/services-carousel-bg.png" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="c-services-carousel__item">
                                        <Link to="/flat/service" className="c-services-carousel__item-content-container">
                                            <div className="c-services-carousel__item-content">
                                                <h3 className="c-services-carousel__item-title">Trend Foresight</h3>
                                                <p className="c-services-carousel__item-summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                            <div className="c-services-carousel__item-link">Tell me more</div>
                                        </Link>
                                        <img src="/images/test/services-carousel-3.png" alt="" className="c-services-carousel__item-media" loading="lazy" />
                                        <div className="c-services-carousel__item-circle">
                                            <img src="/images/test/services-carousel-bg.png" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="c-services-carousel__item">
                                        <Link to="/flat/service" className="c-services-carousel__item-content-container">
                                            <div className="c-services-carousel__item-content">
                                                <h3 className="c-services-carousel__item-title">Tech Experts</h3>
                                                <p className="c-services-carousel__item-summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras cursus tortor nec tempus aliquam. </p>
                                            </div>
                                            <div className="c-services-carousel__item-link">Tell me more</div>
                                        </Link>
                                        <img src="/images/test/services-carousel-1.png" alt="" className="c-services-carousel__item-media" loading="lazy" />
                                        <div className="c-services-carousel__item-circle">
                                            <img src="/images/test/services-carousel-bg.png" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="c-services-carousel__item">
                                        <Link to="/flat/service" className="c-services-carousel__item-content-container">
                                            <div className="c-services-carousel__item-content">
                                                <h3 className="c-services-carousel__item-title">Deep Dives</h3>
                                                <p className="c-services-carousel__item-summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras cursus tortor nec tempus aliquam. </p>
                                            </div>
                                            <div className="c-services-carousel__item-link">Tell me more</div>
                                        </Link>
                                        <img src="/images/test/services-carousel-2.png" alt="" className="c-services-carousel__item-media" loading="lazy" />
                                        <div className="c-services-carousel__item-circle">
                                            <img src="/images/test/services-carousel-bg.png" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="c-services-carousel__item">
                                        <Link to="/flat/service" className="c-services-carousel__item-content-container">
                                            <div className="c-services-carousel__item-content">
                                                <h3 className="c-services-carousel__item-title">Futorials</h3>
                                                <p className="c-services-carousel__item-summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                            </div>
                                            <div className="c-services-carousel__item-link">Tell me more</div>
                                        </Link>
                                        <img src="/images/test/services-carousel-3.png" alt="" className="c-services-carousel__item-media" loading="lazy" />
                                        <div className="c-services-carousel__item-circle">
                                            <img src="/images/test/services-carousel-bg.png" alt="" loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServicesCarouselFlat