import React from "react"
import { Link } from "gatsby"
import useInView from "react-cool-inview";

const ListingItemFlat = ({ title }) => {
    const { observe, inView } = useInView();
    return (
        <div className="c-listing__item {{class}}" ref={observe}>
            <div className={inView ? "c-listing__item-container is-inview" : "c-listing__item-link"}>
                <div className="c-listing__item-media-container">
                    <div className="c-listing__item-media-wrapper">
                        <img src="https://picsum.photos/320/180" alt="" className="c-listing__item-media" loading="lazy"/>
                    </div>
                </div>
                <h3 className="c-listing__item-title">{title}</h3>
            </div>
        </div>
    )
}

class ListingSliceFlat extends React.Component {
    render() {
        return (
            <div className="c-listing">
                <div className="container">
                    <div className="row">
                        <div className="c-listing__header">
                            <h2 className="c-listing__header-title">Title here</h2>
                            <Link to="/flat" className="c-listing__header-link c-btn c-btn--primary">Discover more</Link>
                        </div>
                    </div>
                    <div className="row">
                        <ListingItemFlat title="Title would go here" />
                        <ListingItemFlat title="Title would go here" />
                        <ListingItemFlat title="Title would go here" />
                        <ListingItemFlat title="Title would go here" />
                        <ListingItemFlat title="Title would go here" />
                        <ListingItemFlat title="Title would go here" />
                    </div>
                </div>
            </div>
        )
    }
}

export default ListingSliceFlat