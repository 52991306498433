import React from "react"
import { Link } from "gatsby";
import { useForm } from "react-hook-form";

export const ContactFlat = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        // Do something with form data
    }

    return (
        <div className="c-contact">
            <div className="container">
                <div className="row">
                    <div className="c-contact__media-container">
                        <img src="/images/test/contact.png" alt="" className="c-contact__media" loading="lazy" />
                    </div>
                    <div className="c-contact__form">
                        <h2 className="c-contact__title">Get in touch</h2>
                        <p className="c-contact__summary">If you are interested in finding out more about Outsmart Insight, get in touch.</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <fieldset>
                                <legend className="c-form__legend">Contact</legend>
                                <div className="c-contact__form-fields">
                                    <div className="c-contact__form-field">
                                        <div className="c-form__textbox-field">
                                            <label htmlFor="contactname" className="c-form__label">Name</label>
                                            <input name="contactname" id="contactname" placeholder="Name" className={errors.contactname ? "c-form__textbox error" : "c-form__textbox"} {...register("contactname", { required: true })} />
                                            {errors.contactname && <div className="c-form__validation c-form__validation--error"><p>Please enter your name</p></div>}
                                        </div>
                                    </div>
                                    <div className="c-contact__form-field">
                                        <div className="c-form__textbox-field">
                                            <label htmlFor="contactemail" className="c-form__label">Email</label>
                                            <input name="contactemail" id="contactemail" placeholder="Email" className={errors.contactemail ? "c-form__textbox error" : "c-form__textbox"} {...register("contactemail", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} />
                                            {errors.contactemail && <div className="c-form__validation c-form__validation--error"><p>Please enter a valid email</p></div>}
                                        </div>
                                    </div>
                                    <div className="c-contact__form-field">
                                        <div className="c-form__textarea-field">
                                            <label htmlFor="contactmessage" className="c-form__label">Message</label>
                                            <textarea name="contactmessage" id="contactmessage" placeholder="Message" className={errors.contactmessage ? "c-form__textarea error" : "c-form__textarea"} {...register("contactmessage", { required: true })}></textarea>
                                            {errors.contactmessage && <div className="c-form__validation c-form__validation--error"><p>Please enter your message</p></div>}
                                        </div>
                                    </div>
                                    <div className="c-contact__form-field">
                                        <p className="c-contact__form-consent">By clicking the 'Send Message' button you're confirming that you agree with our following <Link to="/flat">Terms and Conditions</Link>.</p>
                                    </div>
                                    <div className="c-contact__form-field">
                                        <input type="submit" value="Send Message" className="c-contact__submit c-btn c-btn--large c-btn--primary" />
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactFlat;