import React from "react"
import { Link } from "gatsby";

class UseCasesFlat extends React.Component {
    render() {
        return (
            <div className="c-use-cases">
                <div className="container">
                    <div className="row">
                        <div className="c-use-cases__container">
                            <div className="row">
                                <div className="c-use-cases__content-container">
                                    <h2 className="c-use-cases__content-title">Case Studies</h2>
                                    <ul className="c-use-cases__content">
                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</li>
                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</li>
                                    </ul>
                                </div>
                                <div className="c-use-cases__link-container">
                                    <Link to="/flat" className="c-use-cases__link c-btn c-btn--text">Contact our team</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default UseCasesFlat