import React from "react"
import useInView from "react-cool-inview";
import {Link} from "gatsby";

const ProjectListingItemFlat = ({ title }) => {
    const { observe, inView } = useInView();
    return (
        <div className="c-project-listing__item {{class}}" ref={observe}>
            <div className={inView ? "c-project-listing__item-wrapper is-inview" : "c-project-listing__item-wrapper"}>
                <div className="c-project-listing__item-media-container">
                    <div className="c-project-listing__item-media-wrapper">
                        <img src="https://picsum.photos/320/180" alt="" className="c-project-listing__item-media" loading="lazy"/>
                    </div>
                    <div className="c-project-listing__item-category">Category name</div>
                </div>
                <h3 className="c-project-listing__item-title">{title}</h3>
                <p className="c-project-listing__item-summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            </div>
        </div>
    )
}

class ProjectFeaturedFlat extends React.Component {
    render() {
        return (
            <div className="c-project-listing c-project-listing--featured">
                <div className="container">
                    <div className="row">
                        <div className="c-project-listing__header">
                            <h2 className="c-project-listing__header-title">Recent Projects</h2>
                            <div className="c-project-listing__header-link-container">
                                <Link to="/flat" className="c-project-listing__header-link c-btn c-btn--text">Start a Project</Link>
                                <Link to="/flat" className="c-project-listing__header-link c-btn c-btn--primary">See all Projects</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ProjectListingItemFlat title="Title would go here" />
                        <ProjectListingItemFlat title="Title would go here" />
                        <ProjectListingItemFlat title="Title would go here" />
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectFeaturedFlat