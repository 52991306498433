import React from "react"
import { Link } from "gatsby";
import useInView from "react-cool-inview";

const BlogItemFlat = ({ title }) => {
    const { observe, inView } = useInView();
    return (
        <div className="c-blog-listing__item {{class}}" ref={observe}>
            <Link className={inView ? "c-blog-listing__item-link is-inview" : "c-blog-listing__item-link"} to="/flat/insights-article">
                <div className="c-blog-listing__item-media-container">
                    <div className="c-blog-listing__item-media-wrapper">
                        <img src="https://picsum.photos/320/180" alt="" className="c-blog-listing__item-media" loading="lazy"/>
                    </div>
                    <div className="c-blog-listing__item-category">Category name</div>
                </div>
                <h3 className="c-blog-listing__item-title">{title}</h3>
                <p className="c-blog-listing__item-summary">All base UI elements are made using Nested Symbols and shared styles that are logically connected with one another.</p>
                <time className="c-blog-listing__item-date" dateTime="2021-06-12">12 June 2021</time>
            </Link>
        </div>
    )
}

class BlogListingFlat extends React.Component {
    render() {
        return (
            <div className="c-blog-listing">
                <div className="container">
                    <div className="row">
                        <div className="c-blog-listing__filter">
                            <div className="c-blog-listing__filter-label">Filter by:</div>
                            <div className="c-blog-listing__filter-select">
                                <div className="c-selectbox">
                                    <select className="c-selectbox__select">
                                        <option value="">All Categories</option>
                                        <option value="Option">Option</option>
                                        <option value="Option">Option</option>
                                        <option value="Option">Option</option>
                                    </select>
                                    <svg className="c-selectbox__arrow" viewBox="0 0 24 24">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.217 8.502l1.435-1.435L12 15.415l8.348-8.348L21.783 8.5 12 18.284 2.217 8.501z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <BlogItemFlat title="How to save planet earth in the 2020s" />
                        <BlogItemFlat title="10 tips for fundraising for science in UK universities" />
                        <BlogItemFlat title="Reinforced glass, whilst shock absorbent and transparent" />
                        <BlogItemFlat title="How to save planet earth in the 2020s" />
                        <BlogItemFlat title="10 tips for fundraising for science in UK universities" />
                        <BlogItemFlat title="Reinforced glass, whilst shock absorbent and transparent" />
                        <BlogItemFlat title="How to save planet earth in the 2020s" />
                        <BlogItemFlat title="10 tips for fundraising for science in UK universities" />
                        <BlogItemFlat title="Reinforced glass, whilst shock absorbent and transparent" />
                    </div>
                </div>
            </div>
        )
    }
}

export default BlogListingFlat