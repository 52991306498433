import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby";

class MastheadFlat extends React.Component {
    render() {
        const { cssModifier } = this.props
        return (
            <div className={`c-masthead ${ cssModifier }`}>
                <div className="container">
                    <div className="row">
                        <div className="c-masthead__container">
                            <div className="c-masthead__subtitle">Sub title would go here</div>
                            <h1 className="c-masthead__title">Heading would go here</h1>
                            <p className="c-masthead__summary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ut orci quis sem pretium mollis eget eget nulla.</p>
                            <Link to="/flat" className="c-masthead__link c-btn c-btn--large">Link here</Link>
                        </div>
                        {cssModifier === 'c-masthead--media' && (
                            <div className="c-masthead__media-container">
                                <img src="/images/test/masthead.png" alt="" className="c-masthead__media" loading="lazy" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default MastheadFlat

MastheadFlat.propTypes = {
    cssModifier: PropTypes.string
}