import React from "react"

class WhyWorkWithUsFlat extends React.Component {
    render() {
        return (
            <div className="c-why-work">
                <div className="container">
                    <div className="row">
                        <h2 className="c-why-work__title">Why Innovation-Led Organisations Work With Us</h2>
                    </div>
                    <div className="row">
                        <div className="c-why-work__content">Insights from 1000s of scientists in leading labs</div>
                        <div className="c-why-work__separator">
                            <svg fill="none" viewBox="0 0 34 35"><path d="M.138 17.514H33.57M16.856 34.198V.832" stroke="#999" strokeWidth="5" strokeMiterlimit="10"/></svg>
                        </div>
                        <div className="c-why-work__content">Unparalleled coverage across every emerging technology</div>
                        <div className="c-why-work__separator">
                            <svg fill="none" viewBox="0 0 34 20"><path d="M.5 3.28h33.433M.5 16.862h33.433" stroke="#999" strokeWidth="5" strokeMiterlimit="10"/></svg>
                        </div>
                        <div className="c-why-work__result">Insider advantage to keep you at the forefront of innovation</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WhyWorkWithUsFlat