import React from "react"
import { Link } from "gatsby"

class PaginationFlat extends React.Component {
    render() {
        return (
            <nav className="c-pagination" aria-label="Pagination">
                <div className="container">
                    <div className="row">
                        <span className="u-visually-hide" id="label-pagination">Pagination</span>
                        <div className="c-pagination__container" aria-labelledby="label-pagination">
                            <ol className="c-pagination__items">
                                <li className="c-pagination__item c-pagination__item--prev">
                                    <Link to="/flat" className="c-pagination__link">
                                        <svg fill="none" viewBox="0 0 20 20" className="c-pagination__link-icon"><path fillRule="evenodd" clipRule="evenodd" d="M9.593 0 11 1.467 2.813 10 11 18.533 9.593 20 0 10.001 9.593 0Z" fill="#1A1A1A"/><path fill="#1A1A1A" d="M20 9v2H2V9z"/></svg>
                                        <span className="u-visually-hide">Go to</span>
                                        <span className="c-pagination__link-text"> Previous </span>
                                        <span className="u-visually-hide">page</span>
                                    </Link>
                                </li>
                                <li className="c-pagination__item">
                                    <Link to="/flat" className="c-pagination__link is-current">
                                        <span className="u-visually-hide">Go to page</span> 1
                                    </Link>
                                </li>
                                <li className="c-pagination__item">
                                    <Link to="/flat" className="c-pagination__link">
                                        <span className="u-visually-hide">Go to page</span> 2
                                    </Link>
                                </li>
                                <li className="c-pagination__item">
                                    <Link to="/flat" className="c-pagination__link">
                                        <span className="u-visually-hide">Go to page</span> 3
                                    </Link>
                                </li>
                                <li className="c-pagination__item">
                                    <Link to="/flat" className="c-pagination__link">
                                        <span className="u-visually-hide">Go to page</span> 4
                                    </Link>
                                </li>
                                <li className="c-pagination__item c-pagination__item--next">
                                    <Link to="/flat" className="c-pagination__link">
                                        <span className="u-visually-hide">Go to</span>
                                        <span className="c-pagination__link-text"> Next </span>
                                        <span className="u-visually-hide">page</span>
                                        <svg fill="none" viewBox="0 0 20 20" className="c-pagination__link-icon"><path fillRule="evenodd" clipRule="evenodd" d="M10.407 20 9 18.533 17.187 10 9 1.467 10.407 0 20 9.999 10.407 20Z" fill="#1A1A1A"/><path fill="#1A1A1A" d="M0 11V9h18v2z"/></svg>
                                    </Link>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default PaginationFlat