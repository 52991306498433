import * as React from "react"
import LayoutFlat from "../../components/flat/layout"
import BlogLatestFlat from "../../components/flat/blog-latest"
import BlogListingFlat from "../../components/flat/blog-listing"
import CallToActionFlat from "../../components/flat/call-to-action"
import ContactFlat from "../../components/flat/contact"
import ContentFlat from "../../components/flat/content"
import ContentMediaFlat from "../../components/flat/content-media"
import HowItWorksCarouselFlat from "../../components/flat/how-it-works-carousel"
import ListingFlat from "../../components/flat/listing"
import ListingSliceFlat from "../../components/flat/listing-slice"
import LogosFlat from "../../components/flat/logos"
import MastheadFlat from "../../components/flat/masthead"
import MastheadCarouselFlat from "../../components/flat/masthead-carousel"
import NewsletterFlat from "../../components/flat/newsletter"
import PaginationFlat from "../../components/flat/pagination"
import ProjectFeaturedFlat from "../../components/flat/project-featured";
import ProjectListingFlat from "../../components/flat/project-listing";
import ServicesCarousel from "../../components/flat/services-carousel"
import TestimonialFlat from "../../components/flat/testimonial"
import TestimonialCarouselFlat from "../../components/flat/testimonial-carousel"
import UseCasesFlat from "../../components/flat/use-cases"
import WhyWorkWithUsFlat from "../../components/flat/why-work-with-us"

const WidgetsFlat = () => (
    <LayoutFlat>
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Blog Latest/Related</div>
        <BlogLatestFlat heading="Our Insights" />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Blog Listing</div>
        <BlogListingFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Call to Action</div>
        <CallToActionFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Contact</div>
        <ContactFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Content</div>
        <ContentFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Content & Media</div>
        <ContentMediaFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Content & Media Inverted</div>
        <ContentMediaFlat cssModifier="c-content-media--invert" />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>How It Works Carousel</div>
        <HowItWorksCarouselFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Listing</div>
        <ListingFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Listing Slice</div>
        <ListingSliceFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Logos</div>
        <LogosFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Masthead</div>
        <MastheadFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Masthead with Image</div>
        <MastheadFlat cssModifier="c-masthead--media" />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Masthead Carousel</div>
        <MastheadCarouselFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Newsletter</div>
        <NewsletterFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Pagination</div>
        <PaginationFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Project Featured</div>
        <ProjectFeaturedFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Project Listing</div>
        <ProjectListingFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Services Carousel</div>
        <ServicesCarousel />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Testimonial</div>
        <TestimonialFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Testimonial Carousel</div>
        <TestimonialCarouselFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Use Cases</div>
        <UseCasesFlat />
        <div style={{color: "red", fontSize: "50px", margin: "100px 0 30px 0", textAlign: "center"}}>Why Work With Us</div>
        <WhyWorkWithUsFlat />
    </LayoutFlat>
)

export default WidgetsFlat
